* {
    box-sizing: border-box;
}

$backgroundColor: rgb(255, 255, 255);
$textColor: #2b2c48;
$paragraphColor: #636b6f;
$socialIconColor: rgb(63, 63, 63, 100);
$borderBottom: #000000;
$buttonColor: #5c5c6d;
$boxShadow: rgba(26, 21, 16, 0.062);
$wheat: wheat;

$backgroundColorDark: rgb(0, 0, 0);
$textColorDark: #d4d3b7;
$paragraphColorDark: #9c9490;
$socialIconColorDark: rgb(221, 208, 174, 100);
$borderBottomDark: #eee;
$buttonColorDark: #a3a392;
$boxShadowDark: #a9a76910;
$wheatDark: black;

.darkTheme {
    .card {
        background-color: $backgroundColorDark;
    }

    .card {
        color: $textColorDark;
    }

    .cardDesc {
        color: $paragraphColorDark;
    }

    .cardButtons {
        background-color: $backgroundColorDark;

        button {
            color: $buttonColorDark;

            &.isActive,
            &:hover {
                color: $textColorDark ;
                border-bottom: 3px solid $borderBottomDark;
                background: linear-gradient(to top,
                        rgba(211, 226, 255, 0.4) 0%,
                        rgba(207, 204, 255, 0.2) 44%,
                        rgba(127, 199, 231, 0) 100%);
            }
        }
    }

    .cardContact {
        a {
            color: $textColorDark;
        }
    }

    .body {
        color: $textColor;
        align-content: center;
        background-color: #1d1d1d;

        a {

            path,
            circle {
                fill: $socialIconColorDark;
            }
        }

        button.submit {
            background-color: $wheat;
            color: $textColor;
        }
        path,
        circle {
            fill: $textColorDark;
        }
    }

    .cardTimeline {

        &:after {
            background: linear-gradient(to top,
                    $borderBottom 0%,
                    $borderBottomDark 100%);
        }
    }

    .cardItemDesc {
        color: lightgray;

        a {
            color: whitesmoke;

            &::after {
                content: "";
                background-size: 400% 400%;
                background: linear-gradient(90deg, rgba(255, 0, 0, 0.5) 0%, rgba(0, 255, 0, 0.5) 50%, rgba(0, 0, 255, 0.5) 100%);
                position: absolute;
                bottom: 0;
                z-index: -1;
                transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
                background-size: 400% 400%;
                animation: gradient 18s ease infinite;
                left: -6px;
                padding: 0 12px;
                border-radius: 5px;
                width: 100%;
                height: 100%;
            }

            &:hover:after {
                left: 10px;
                background-size: 400% 400%;
                animation: gradient 8s ease infinite;
                padding: 0 8px;
                border-radius: 5px;
                width: calc(100% - 9px);
                height: calc(100% - 15px);
            }
        }
        img{
            filter:brightness(0.7);
        }
    }
}

.body {
    color: $textColor;
    font-family: "Jost", sans-serif;
    background-repeat: repeat;
    background-size: cover;
    background: black;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
    align-content: center;
    flex-wrap: wrap;
    padding: 4px;
    margin: auto;
    transition: all 1s;

    path,
    circle {
        fill: black;
    }

    a {
        svg {
            margin: auto;
        }

        path,
        circle {
            fill: $socialIconColor;
            transition: 1s all ease-in-out;
        }
    }

    button.submit {
        animation: fade-in 500ms forwards;
        padding: 10px 20px;
        border: none;
        margin: auto;
        background: $wheatDark;
        border-radius: 8px;
        font-family: "Jost", sans-serif;
        font-weight: 500;
        color: $borderBottomDark;
        width: 80vw;
        cursor: pointer;
        max-width: 320px;
    }
}

.card {
    //max-width: 740px;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    background-color: $backgroundColor;
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    border-radius: 10px;
    max-width: 690px;
    margin: auto;
}

.card[data-state='#about'] {
    //max-height: 85vh;
    text-align: justify;
    text-align-last: center;

    .cardMain {
        transition: all 1s;
        padding-top: 0;
    }
}

.card[data-state="#contact"] {
    //max-height: 80vh;
    text-align: justify;
    text-align-last: center;
}

.card[data-state="#experience"] {
    //height: 90vh;
    text-align: justify;
    text-align-last: center;
}

.card.isActive {

    .nightToggle {
        margin: 28px 0 0 auto;
    }

    .cardSocial {
        display: none;
    }

    .cardHeader {
        height: 80px;
    }

    .cardCover {
        height: 100px;
        top: -50px;
    }

    .cardAvatar {
        transform: none;
        left: 20px;
        width: 50px;
        height: 50px;
        bottom: 10px;
    }

    .cardFullname,
    .cardJobtitle {
        left: 86px;
        transform: none;
    }

    .cardFullname {
        bottom: 18px;
        font-size: 19px;
    }

    .cardJobtitle {
        bottom: 16px;
        letter-spacing: 1px;
        font-size: 10px;
    }
}

@keyframes squeezIn {
    0% {
        margin-right: 20px;
        margin-left: 20px;
    }

    100% {
        margin-right: 5px;
        margin-left: 5px;
    }
}

@keyframes leftEnter {
    0% {
        transform: translateX(-20px);
    }

    100% {
        transform: translateX(0px);
    }
}

.card.isInitial {
    .cardSocial {
        a {
            animation: squeezIn 0.3s linear;
        }
    }
}

.card.isPassive {
    .cardSocial {
        display: block;
        animation: leftEnter 0.5s ease-out;
    }

    .cardHeader {
        height: 120px;
    }

    .cardCover {
        height: 180px;
        top: -80px;
    }

    .cardAvatar {
        transform: none;
        left: 35px;
        width: 50px;
        height: 50px;
        bottom: 20px;
    }

    .cardFullname,
    .cardJobtitle {
        left: 86px;
        transform: none;
    }

    .cardFullname {
        bottom: 28px;
        font-size: 19px;
        left: 110px;
    }

    .cardJobtitle {
        bottom: 26px;
        left: 110px;
        letter-spacing: 1px;
        font-size: 10px;
    }
}

.cardHeader {
    position: relative;
    display: flex;
    height: 200px;
    flex-shrink: 0;
    width: 100%;
    z-index: 99;
    transition: 0.3s;

    * {
        transition: 0.3s;
    }
}

.cardCover {
    width: 100%;
    height: 100%;
    position: absolute;
    height: 160px;
    top: -20%;
    left: 0;
    will-change: top;
    background-size: cover;
    background-position: center;
    filter: blur(30px);
    transform: scale(1.2);
    transition: 0.5s;
}

.cardAvatar {
    width: 100px;
    height: 100px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-64px);
}

.cardFullname {
    position: absolute;
    bottom: 0;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    transform: translateY(-10px) translateX(-50%);
    left: 50%;
}

.cardJobtitle {
    position: absolute;
    bottom: 0;
    font-size: 11px;
    white-space: nowrap;
    font-weight: 500;
    opacity: 0.7;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-7px);
}

.cardMain {
    position: relative;
    flex: 1;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    overflow: auto;
}

.cardSubtitle {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 8px;
    display: flex;

    p {
        margin: auto;
    }

    span {
        //font-family: monospace;
        font-weight: 500;
        color: darkgray;
        margin: auto 0 auto auto;
    }
}
.map {
    filter: grayscale(1) invert(1);
    border: none;
    max-width: 300px;
    border-radius:20px;
    animation: leftEnter 0.5s ease-out;
}
.cardContent {
    padding: 20px;
}

.cardDesc {
    line-height: 1.6;
    color: $paragraphColor;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    margin-bottom: 20px;
}

.contcatSocial,.cardSocial {

    //display: flex;
    //align-items: center;
    //padding: 0 20px;
    //margin-bottom: 30px;
    svg {
        fill: rgb(165, 181, 206);
        width: 16px;
        display: block;
        transition: 0.3s;
    }

    a {
        color: $socialIconColor;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        background-color: rgba(93, 133, 193, 0.05);
        border-radius: 50%;
        margin-right: 10px;

        &:hover {
            svg {
                fill: darken(rgb(165, 181, 206), 20%);
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}


.cardButtons {
    display: flex;
    background-color: $backgroundColor;
    margin-top: auto;
    position: sticky;
    bottom: 0;
    left: 0;
    transition: 0.3s;

    button {
        flex: 1 1 auto;
        user-select: none;
        background: 0;
        font-size: 13px;
        border: 0;
        padding: 15px 5px;
        cursor: pointer;
        color: $buttonColor;
        transition: 0.3s;
        font-family: "Jost", sans-serif;
        font-weight: 500;
        outline: 0;
        border-bottom: 3px solid transparent;

        &.isActive,
        &:hover {
            color: $textColor;
            border-bottom: 3px solid $borderBottom;
            background: linear-gradient(to bottom,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.2) 30%,
                    rgba(211, 211, 211, 0.3) 60%,
                    rgba(115, 115, 115, 0.5) 100%);
        }
    }
}

.cardSection {
    display: none;

    &.isActive {
        display: block;
        animation: fadeIn 1s both;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translatey(40px);
    }

    100% {
        opacity: 1;
    }
}

.cardTimeline {
    margin-bottom: 30px;
    position: relative;
    text-align: justify;
    text-align-last: left;

    &:after {
        background: linear-gradient(to top,
                $borderBottomDark 0%, $borderBottom 100%);
        content: "";
        left: 42px;
        width: 2px;
        top: 0;
        height: 100%;
        position: absolute;
        content: "";
    }
}

.cardItem {
    position: relative;
    padding-left: 60px;
    padding-right: 20px;
    padding-bottom: 30px;
    z-index: 1;

    &:last-child {
        padding-bottom: 5px;
    }

    &:after {
        content: attr(data-year);
        width: 10px;
        position: absolute;
        top: 0;
        left: 37px;
        width: 8px;
        height: 8px;
        line-height: 0.6;
        border: 2px solid #fff;
        font-size: 11px;
        text-indent: -35px;
        border-radius: 50%;
        color: rgba(#868686, 0.7);
        background: red;
    }
}

.cardItemTitle {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.cardItemDesc {
    font-size: 13px;
    color: #6f6f7b;
    line-height: 1.5;
    font-family: "DM Sans", sans-serif;

    a {
        padding: 0px 4px;
        position: relative;
        color: $textColor;
        cursor: pointer;

        &::after {
            content: "";
            background-size: 400% 400%;
            background: linear-gradient(45deg, rgba(255, 0, 0, 0.4) 0%, rgba(0, 255, 0, 0.2) 43%, rgba(0, 0, 255, 0.2) 100%);
            position: absolute;
            bottom: 0;
            z-index: -1;
            transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
            background-size: 400% 400%;
            animation: gradient 18s ease infinite;
            left: -6px;
            padding: 0 12px;
            border-radius: 5px;
            width: 100%;
            height: 100%;
        }

        &:hover:after {
            left: 10px;
            background-size: 400% 400%;
            animation: gradient 8s ease infinite;
            padding: 0 8px;
            border-radius: 5px;
            width: calc(100% - 9px);
            height: calc(100% - 15px);
        }
    }
    img {
        max-width: 540px;
        width: 60vw;
        border-radius: 12px;
        margin-top: 12px;
    }
}

.cardContactWrapper {
    margin-top: 20px;
}

.cardContact {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #6f6f7b;
    font-family: "DM Sans", sans-serif;
    line-height: 1.6;
    cursor: pointer;

    &+& {
        margin-top: 16px;
    }

    svg {
        animation: leftEnter 0.5s ease-out;
        flex-shrink: 0;
        width: 30px;
        min-height: 34px;
        margin-right: 12px;
        transition: 0.3s;
    }

    a {
        text-decoration: none;
        color: gray;
        animation: fade-in 500ms forwards;
        margin-left: 12px;
    }
}

.contactMe {
    border: 0;
    outline: none;
    background: linear-gradient(to right,
            rgba(83, 200, 239, 0.8) 0%,
            rgba(81, 106, 204, 0.8) 96%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    color: #fff;
    padding: 12px 16px;
    width: 100%;
    border-radius: 5px;
    margin-top: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    transition: 0.3s;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes spin-rev {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-180deg);
    }
}

.nightToggle {
    display: flex;
    flex-direction: row;
    width: 60px;
    margin: 60px 0 0 auto;
}

.themeIcon {
    position: relative;
    width: 30px;
    height: 30px;

    .isPassive {
        animation: spin 1s ease-out;
        position: absolute;
    }

    .isActive {
        position: absolute;
        animation: spin-rev 1s ease-out;
    }
}

.loading {
    width: 50px;
    height: 40px;
    margin: 40px auto;
}
