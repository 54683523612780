$textColor: #d4d3b7;

.container {
  font-family: "Jost", sans-serif;
  max-width: 260px;
  margin: auto;
  display: block;
  padding: 0 5px 5px 0;
}

h2 {
  text-align: center;
  margin-bottom: 50px;
}

h2 small {
  font-weight: normal;
  color: #888;
  display: block;
}

.footer {
  text-align: center;
}

.footer a {
  color: #53B2C8;
}

/* form starting stylings ------------------------------- */
.group {
  position: relative;
}
input.wrong{
  color: red;
}
input.right{
  color: yellowgreen;
}
input {
  font-size: 18px;
  padding: 10px 10px 10px 10px;
  display: block;
  width: 80vw;
  max-width: 260px;
  border: none;
  color: grey;
  background: transparent;
  border-bottom: 1px solid #757575;
}

input:focus {
  outline: none;
}

/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.up {
  top: -20px;
  font-size: 14px;
  color: $textColor;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 80vw;
  max-width: 260px;
}

.bar:before,
.bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: $textColor;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

/* active state */
input:focus~.bar:before,
input:focus~.bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: $textColor;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: $textColor;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: $textColor;
  }

  to {
    width: 0;
    background: transparent;
  }
}